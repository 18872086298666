<template>
  <div>
    <span class="subtitle-1 font-weight-bold">Pares</span>
    <v-list-item-group v-model="selected" multiple @change="emitInput">
      <v-list-item
        v-for="pointOrder in items"
        :key="pointOrder.id"
        class="list-item mt-3"
        :value="pointOrder"
      >
        <template v-slot:default="{ active }">
          <v-list-item-icon>
            <v-icon>
              {{ active ? "check_circle" : "panorama_fish_eye" }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <view-tracking-impaction-point
              v-if="pointOrder.trackingPoint"
              justify="start"
              :tracking-point="pointOrder.trackingPoint"
              :impaction-point="pointOrder.impactionPoint"
              :expand-images="false"
            />
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </div>
</template>

<script>
export default {
  name: "ListPointsOrderFlowTypes",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selected: [],
  }),
  methods: {
    emitInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>
