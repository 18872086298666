<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="close" />
        <span>Tipo de Fluxo: {{ selected.name }}</span>
      </v-card-title>
      <v-card-text>
        <div class="pa-5">
          <list-points-order
            class="mt-5"
            :items="selected.pointsOrder"
            @input="inputPointsOrder"
          />
          <transition name="fade">
            <div v-show="hasParTrauma" class="mt-5">
              <v-text-field
                ref="affectedSpotInput"
                v-model="diagnoseFlowType.affectedSpot"
                label="Local afetado (Par trauma)"
                :autofocus="hasParTrauma"
                outlined
              />
            </div>
          </transition>
          <!--
          <v-textarea
            v-model="diagnoseFlowType.instructions"
            label="Instruções ao paciente"
            maxlength="500"
            counter
            outlined
            class="mt-3"
          />
           -->
          <comments v-model="comments" />
          <div class="d-flex flex-column mt-3">
            <v-btn large color="secondary" @click="addToDiagnose">
              Adicionar ao diagnóstico
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ListPointsOrder from "@/domains/therapy/steps/execute/domains/flow-types/presentation/components/ListPointsOrder";
import Comments from "@/domains/therapy/shared/presentation/components/Comments";

export default {
  components: { Comments, ListPointsOrder },
  data: () => ({
    diagnoseFlowType: {
      instructions: "",
      pointsOrder: [],
      affectedSpot: "",
    },
    expanded: [],
    comments: {},
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.flowTypes;
      },
      set(value) {
        this.$store.commit("dialogs/setFlowTypes", value);
      },
    },
    selected() {
      return this.$store.state.flowTypes.selected;
    },
    flowTypes() {
      return this.$store.state.flowTypes.flowTypes;
    },
    hasParTrauma() {
      return this.diagnoseFlowType.pointsOrder.some(
        (pointOrder) => pointOrder.trackingPoint.name.toLowerCase() === "trauma"
      );
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    inputPointsOrder(value) {
      this.diagnoseFlowType.pointsOrder = value;
    },
    async addToDiagnose() {
      if (!this.validate()) return;

      const diagnoseFlowTypeWithComments = {
        ...this.diagnoseFlowType,
        therapistComments: this.comments.therapistComments,
        patientComments: this.comments.patientComments,
        flowType: this.selected,
      };
      this.$store.commit("diagnosis/addFlowType", diagnoseFlowTypeWithComments);
      this.$toasted.global.defaultSuccess();
      this.clearDiagnose();
      this.close();
    },
    validate() {
      if (this.hasParTrauma && !this.diagnoseFlowType.affectedSpot) {
        this.$toasted.global.error({ message: "Indique o ponto afetado." });
        return false;
      }

      const diagnoseFlowTypes = this.$store.state.diagnosis.diagnose.flowTypes;

      if (
        diagnoseFlowTypes.some((dft) => dft.flowType.id === this.selected.id)
      ) {
        this.$toasted.global.error({
          message: "Tipo de fluxo já adicionado ao diagnóstico.",
        });
        return false;
      }

      return true;
    },
    clearDiagnose() {
      this.diagnoseFlowType = {
        instructions: "",
        pointsOrder: [],
      };
      this.expanded = [];
      this.$store.commit("flowTypes/clearSelected");
    },
  },
};
</script>

<style />
